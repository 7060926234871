<template>
	<div>
		<v-overlay :value="overlay" absolute >
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-toolbar flat>
			<v-icon left>mdi-contacts-outline</v-icon>
			<v-toolbar-title>CONTACTOS</v-toolbar-title>
			<v-divider class="mx-4" vertical></v-divider>			
			<v-spacer></v-spacer>
			<v-btn 
				color="blue" 
				small 
				dark 
				@click="cargarDatosPartys"
			>
				<v-icon>mdi-ray-start-end</v-icon>Unificar
			</v-btn>
		</v-toolbar>
		<v-toolbar flat>
			<v-row>
				<v-col cols="12" md="4">
					<v-text-field
						clearable
						label="Nombres"
						type="search"
						v-model="nombres"
						@keyup.enter="getPartys"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						clearable
						label="Apellidos"
						type="search"
						v-model="apellidos"
						@keyup.enter="getPartys"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<v-select
						label="Estado"
						clearable
						v-model="estado"
						:items="estados"
						item-text="description"
						item-value="status_id"
						@change="getPartys"
					/>
				</v-col>
			</v-row>		
		</v-toolbar>
		<v-data-table
			v-model="partySelected"
			:headers="headers"
			:items-per-page="itemsPerPage"
			:items="dataTable"
			item-key="party_id"
			show-select
			class="elevation-1"
			hide-default-footer
		>
			<template v-slot:footer>
				<v-pagination
					class="mt-10"
					v-model="currentPage"
					:length="pageCount"
					@input="handlePageChange"
					total-visible="10"
				/>
			</template>
		</v-data-table>
		<v-dialog 
			v-model="dialogUnificar" 
			fullscreen 
			persistent 
			background-color="white"
			:retain-focus="false"
			no-click-animation
			
		>
			<v-overlay :value="overlay" absolute >
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-toolbar 
				height="50" 
				class=""
				color="blue" 
				flat 
				dark
			>
				<v-btn 
					class="white--text" 
					text 
					small 
					@click="closeDialogUnificar"
				>
					<v-icon left>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>
					<v-icon>mdi-ray-start-end</v-icon> UNIFICAR PARTIES
				</v-toolbar-title>
				<v-divider 
					class="mx-4" 
					inset 
					vertical
				/>
				<v-spacer></v-spacer>
				<v-btn 
					class="white--text" 
					text 
					 
					@click="unificarParties"
				>
					<v-icon left>mdi-floppy</v-icon> ACTUALIZAR
				</v-btn>
			</v-toolbar>			
			<v-card>
				<v-col cols="12">
					<v-simple-table>
						<template v-slot:default>
							<thead>							
								<tr>														
									<th
										v-for="(hu, x) in headersUnificar"
										:key="x"
									>
										<div v-if="x>0" class="d-flex align-center">						
											<input 
												class="radio-header" 
												type="radio" 
												name="partyUnificado" 
												:id="`party_${hu.value}`"
												@click="setValorColumna(hu)"
											>
											<label  :for="`party_${hu.value}`" class="ms-2 label-header"><b>{{ hu.text }}</b></label>
										</div>
										
										<div v-else class="label-header d-flex align-center"> <b>{{ hu.nombre }}</b></div>
									</th>										
								</tr>								
							</thead>
							<tbody>
								<tr
									v-for="(dm, y) in dataMap"
									:key="y"
								>
									<td
										v-for="(c, z) in dm"
										:key="z"
									>
										<div v-if="z>0" class="d-flex align-center">						
											<input 
												:class="`radio-header radio-valor radio-valor-${c.split('-')[1]} ${dm[0].split(' ').join('_')}`" 
												type="radio" 
												:name="`${dm[0]}`" 
												:id="`${dm[0]}_${z}`"
												:disabled="c.split('-')[0] == ''"
												:value="c.split('-')[0]"
												:checked="c.split('-')[0] != ''"
											>
											<label :for="`${dm[0]}_${z}`" class="ms-2 label-header" style="width:200px">{{ c.split('-')[0].replaceAll('>','') }}</label>
										</div>
										<span v-else class="label-header"><b>{{ c.split('-')[0].replaceAll('>','') }}</b></span>
									</td>								
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

	import { mapState, mapMutations, mapActions } from "vuex";
	import Vue from "vue";

	export default {
		name: "UnificarParty",
		data: () => ({
			show: false,
			valop1:"10",
			valop2:"20",
			op1:"op1",
			op2:"op2",
			grupo1: "",
			grupo2:"",
			grupoNom:[],
			valop21: "30",
			valop22: "40",
			nombres:'',
			apellidos:'',
			requiredRule: [(v) => !!v || "El campo es requerido"],
			options: {},
			setfilters: [],
			selected: [],
			users: [],
			totalItems: 1,
			currentPage: 1,
			itemsPerPage: 25,
			pageCount: 1,
			overlay: false,
			dialogUnificar:false,
			partyIdUnificado:null,
			dataTable:[],
			partySel: [],
			partys: [],
			partySelected:[],
			dataMap:[],
			headersUnificar:[],
			dataTableUnificar:[],									
			estados:[
				{description: 'Habilitado', status_id: 'PARTY_ENABLED'},
				{description: 'Deshabilitado', status_id: 'PARTY_DISABLED'}
			],
			required: [(v) => !!v || "El campo es requerido"],
			headers: [			
				{ text: "Party", align: "start", value: "party_id" },
				{ text: "Nombres y apellidos", align: "start", value: "cliente_nombres" },
				{ text: "Tipo identificación", align: "start", value: "tipo_identificacion" },
				{ text: "Identificación", align: "start", value: "identificacion" },
				{ text: "Creado el", align: "start", value: "created_date" },
				{ text: "Estado", align: "start", value: "estado" }			
			],
			estado: 'PARTY_ENABLED',
			dialogAgendarEvento: false,
			dialogCambioEstado: false
		}),
		computed: {

			...mapState("master", ["loadingBtn", "companies"]),

			...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

			...mapState("master", ["loadingTable", "tenantId", "user"]),

		},
		methods: {

			...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

			...mapActions("master", ["requestApi", "alertNotification"]),

			getPartys(){

				this.setLoadingTable(true);
				this.overlay = true;
				
				this.setUrl("unificar-parties")

				this.requestApi({
					method: "GET",
					data: {
						typeList: "listarParties",
						nombres: this.nombres,
						apellidos: this.apellidos,
						paginator:true,
						page: this.currentPage,
						itemsPerPage: this.itemsPerPage,
						estado: this.estado
					}
				}).then(res => {          

					console.log('res',res)
					this.partySelected = []
					this.pageCount = res.data.page_count;
					this.currentPage = res.data.page == 0 ? 1 : res.data.page;
					this.dataTable = res.data._embedded.unificar_parties					
					this.overlay = false;

				}).then(() => {

					this.setLoadingTable(false);
					this.overlay = false;

				})

			},

			cargarDatosPartys() {

				if(this.partySelected.length < 2){

					this.alertNotification({
						param: {
							html: "Debe seleccionar al menos dos partys",
							timer: 10000,
							title: "Error",
							icon: "error",
							confirmButtonColor: "red",
						}
					})

					return false

				}

				this.overlay = true

				this.requestApi({
					method: "GET",
					data: {
						typeList: "cargarDatosPartys",
						idPartys: this.partySelected.map(obj => {
							let party_id = obj.party_id
							return party_id
						})
					}
				}).then(res => {          

					const {map , headers} = res.data.detail

					this.headersUnificar = []

					console.log('res',res)
					
					headers.forEach(h => {

						this.headersUnificar.push({ text: h.nombre, align: "start", value: h.party_id })

					});

					this.dataTableUnificar = map

					this.dataMap = map
					this.dialogUnificar = true
					this.overlay = false

					this.partyIdUnificado = headers[1].party_id

					setTimeout(() =>{

						document.getElementById(`party_${this.partyIdUnificado}`).checked = true
						this.setValorColumna({value : this.partyIdUnificado})

					},1000)

				}).then(() => {
					
					this.overlay = false

				}).catch(()=>{
					this.overlay = false
				})		

			},
			
			closeDialogUnificar(){

				this.dialogUnificar = false
				this.partySelected = []

			},

			handlePageChange(value) {
				this.getPartys();
			},

			setValorColumna(columna){

				console.log('columna',columna)

				this.partyIdUnificado = columna.value

				Array.from(document.getElementsByClassName('radio-valor')).forEach(ele => { ele.checked = false })

				Array.from(document.getElementsByClassName(`radio-valor-${columna.value}`)).forEach(ele => { ele.checked = true })

			},

			unificarParties(){

				if(this.partyIdUnificado == '' || this.partyIdUnificado  == null){

					Vue.swal({
						html: 'Debe escoger el party donde quedaran los datos',
						icon: 'warning'
					})
					return false

				}

				let string = Math.random().toString(36).substr(0,20)

				Vue.swal({
					html : `<p>Está seguro de actualizar los datos al party seleccionado?.</p> <p><b>¡.ESTA ACCIÓN NO SE PUEDE DESHACER.!</b></p> <p> Palabra de seguridad: </p> <p> <b>${string}</b> </p>`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Aceptar",
					cancelButtonText: "Cerrar",
					input: "text",
					inputPlaceholder: 'Escriba la palabra de seguridad',
					...this.paramAlertQuestion,
					inputValidator: value => {

						return new Promise(resolve => {

							if (value == "" || value != string) {
								
								resolve("Verifique que la palabra de seguridad este bien escrita")

							} else {

								resolve()
								
							}

						})

					}
				})
				.then(result => {

					if (result.isConfirmed) {

						this.overlay= true
						
						let partysNoSelected = this.partySelected.filter(e=> e.party_id != this.partyIdUnificado)
						.map(obj => { return obj.party_id })

						let dataMapStore ={
							partyId: this.partyIdUnificado,
							partysNoSelected
						}
						
						this.dataMap.forEach(map => {
							
							let clase = map[0].split(' ').join('_')

							Array.from(document.getElementsByClassName(clase))
							.filter(ele => ele.checked ).forEach(ele => { dataMapStore[clase] = ele.value })
							
						})

						console.log(dataMapStore,partysNoSelected,'this.dataMap',this.dataMap)
					
						this.requestApi({
							method: "POST",
							data: {
								accion: "unificarParties",
								dataMapStore
							}
						}).then(res => {          
							
							console.log('res',res)

							this.alertNotification({
								param: {
									html: res.data.res.msg
								}
							})

							this.headersUnificar = []
						
							this.getPartys()

							this.closeDialogUnificar()
							
							this.overlay = false

						}).then(() => {
							
							this.overlay = false

						}).catch(()=>{

							this.overlay = false

						})
							
					}

				})

			}
		
		},
		mounted() {

			//this.cargarDatosOportunidad(this.$route.params.sales_opportunity_id);
			this.setTitleToolbar("UNIFICAR PARTIES")
			this.getPartys()		

		},
	}
</script>

<style>

	.radio-header{
		width: 20px;
		height: 20px;
		position: relative;
		top: 4px;
		cursor: pointer;
	}

	.label-header{
		font-size: 14px;
		text-transform: uppercase;
		cursor: pointer;
	}

</style>